import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorityDocument, BadgeColor, BaseForm, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { combineLatest, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs';
import { AuthorityDataService } from '../../service/data/authority-data.service';

@Component({
  selector: 'app-authority-item-form',
  templateUrl: './authority-item-form.component.html',
  styleUrls: ['./authority-item-form.component.scss'],
})
export class AuthorityItemFormComponent extends BaseForm<AuthorityDocument> implements OnInit {
  uniqueNameHasError: boolean = false;
  cdataAckReqProgress: any;
  badgeColors = BadgeColor;
  options: any;
  constructor(
    public viewModeService: ViewModeService,
    public requestService: AuthorityDataService
  ) {
    super(viewModeService, ModuleKeywords.AuthorityDocument);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      versionName: new FormControl(null, Validators.required),
      aliasName: new FormControl(null),
      description: new FormControl(null),
      uniqueId: new FormControl({ value: null, disabled: true }),
      validFrom: new FormControl(null),
      validTo: new FormControl(null),
      externalReferences: new FormControl(null),
    });

    this.formGroup?.controls?.uniqueId?.markAsDirty();
    this.formGroup?.controls?.uniqueId?.markAsTouched();

    this.formGroup?.controls?.validFrom?.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (
        this.formGroup?.controls?.validTo?.value &&
        new Date(res) > new Date(this.formGroup?.controls?.validTo?.value)
      ) {
        this.formGroup.controls?.validTo?.patchValue(null);
      }
    });

    combineLatest([
      this.formGroup.controls.name.valueChanges.pipe(debounceTime(500), distinctUntilChanged()),
      this.formGroup.controls.versionName.valueChanges.pipe(debounceTime(500), distinctUntilChanged()),
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([name, versionName]) => name != this.data?.name || versionName != this.data?.versionName),
        switchMap(([name, versionName]) => {
          return this.requestService.checkUniqueName({ name, versionName });
        })
      )
      .subscribe((res) => {
        if (res?.data?.availableName) {
          this.uniqueNameHasError = !!!res?.data?.available;
          this.formGroup?.controls?.uniqueId?.patchValue(res?.data?.availableName);
        } else {
          this.uniqueNameHasError = !!!res?.data;
          this.formGroup?.controls?.uniqueId?.patchValue(
            this.formGroup?.value?.name + '.' + this.formGroup?.value?.versionName
          );
        }
      });
  }
}
