<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.aliasName.label' | translate: 'Alias Name'"
        [placeholder]="moduleFieldString + '.aliasName.placeholder' | translate: 'Enter Alias Name'"
        [control]="formGroup?.controls?.aliasName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.versionName.label' | translate: 'Version'"
        [placeholder]="moduleFieldString + '.versionName.placeholder' | translate: 'Enter Version'"
        [control]="formGroup?.controls?.versionName"
        [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
      ></app-basic-input>
      <!-- <app-number-input
      label="Version Number"
      [placeholder]="'Enter Version Number'"
      [control]="formGroup?.controls?.versionName"
      [viewMode]="fieldViewMode=='create'?'create':'view'"
      [maxFractionDigits]="0"
    ></app-number-input> -->
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.uniqueId.label' | translate: 'Unique Name'"
        [placeholder]="moduleFieldString + '.uniqueId.placeholder' | translate: 'Enter Unique Name'"
        [control]="formGroup?.controls?.uniqueId"
        [viewMode]="fieldViewMode == 'create' ? 'create' : 'view'"
        [customError]="uniqueNameHasError"
        [customErrorLabel]="
          moduleFieldString + '.uniqueId.customErrorLabel' | translate: 'Name and Version Number Combination is Taken'
        "
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.validFrom.label' | translate: 'Valid From'"
        [placeholder]="moduleFieldString + '.validFrom.placeholder' | translate: '2022-11-22'"
        [name]="'validFrom'"
        [showIcon]="true"
        [control]="formGroup?.controls?.validFrom"
        [viewMode]="fieldViewMode"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.validTo.label' | translate: 'Valid To'"
        [placeholder]="moduleFieldString + '.validTo.placeholder' | translate: '11/22/2023'"
        [name]="'validTo'"
        [showIcon]="true"
        [control]="formGroup?.controls?.validTo"
        [viewMode]="fieldViewMode"
        [minDate]="formGroup?.controls?.validFrom?.value | toDateObj"
      ></app-date-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'115px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-list-strings
        [label]="moduleFieldString + '.externalReferences.label' | translate: 'External References'"
        [placeholder]="moduleFieldString + '.externalReferences.placeholder' | translate: 'Add Reference'"
        [hideLabel]="false"
        formControlName="externalReferences"
        [viewMode]="fieldViewMode"
        name="externalReferences"
      ></app-list-strings>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
